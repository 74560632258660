// TODO BET-82: https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
// .grecaptcha-badge { visibility: hidden; }

// https://divimundo.com/en/blog/how-to-move-the-recaptcha-v3-badge-to-the-left/
.grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 4px !important;
}
.grecaptcha-badge:hover {
    width: 256px !important;
}
