.react-chatbot-kit-chat-btn-send {
    width: 20%;
    height: 90%;
    padding-top: -100px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 150px;
}

.react-chatbot-kit-chat-message-container {
    height: 80%;
    padding-right: 1.5rem;
    overflow: auto;
}

.react-chatbot-kit-chat-container {
    background-color: white;
    width: 25em;
    border-radius: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.react-chatbot-kit-chat-input-form {
    border-radius: 50px;
}

.react-chatbot-kit-chat-bot-message {
    width: 80%;
}
